<template >
  <div class="table-wrap">
    <div class="table-container" style="margin-bottom:24px;">

      <a-card
        style="width: 100%"
        :tab-list="tabListNoTitle"
        :active-tab-key="noTitleKey"
        :headStyle = "tab-header"
        :extra = "slot"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >

        <div v-if="noTitleKey === 'info'">
          <div class="title"><div class="text"><span>会员信息</span></div></div>
          <a-descriptions title="" layout="">
            <a-descriptions-item label="注册账号"  :span="24">{{userinfo.phone}}</a-descriptions-item>
            <a-descriptions-item label="会员级别"  :span="24">{{userinfo.vip}}</a-descriptions-item>
            <a-descriptions-item label="微信绑定"  :span="24"><span v-if="userinfo.openid">已绑定</span><span v-else>未绑定<span style="color:#24a0ff;" @click="bindwx">【点击绑定】</span></span></a-descriptions-item>
            <a-descriptions-item label="套餐期限"  :span="24"><span v-if="userinfo.expiretime">{{userinfo.expiretime}}<span style="color:#24a0ff;" class="down-button" @click="buyVip">【套餐续费】</span></span> <span v-else>未订购套餐<span style="color:#24a0ff;" class="down-button" @click="buyVip">【套餐购买】</span></span> </a-descriptions-item>
          </a-descriptions>


        </div>
        <div v-else-if="noTitleKey === 'subaccount'">
          <div style="display:flex;justify-content:space-between">
            <div class="title"><div class="text"><span>子账户管理</span></div></div>
            <div class="addButton">
              <a-button class="filter-btn" type="primary" @click="addAccount">添加账户</a-button>
            </div>
          </div>
          <div class="table">
              <a-table size="middle" :columns="subaccountColumns" :row-key="record => record.id" :data-source="list_subaccount"
                :pagination="subaccountPagination" :loading="loading" @change="handleChangeSubaccount">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.dataIndex == 'userid'">
                    <div>
                      <span>{{ record.userid }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'phone'">
                    <div>
                      <span>{{ record.phone }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'operate'">
                    <div>
                      <a-popconfirm
                        title="确认删除该账号？"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="delSubaccount(record.userid)"
                        @cancel="cancel"
                      >
                        <span class="down-button" >删除</span>

                      </a-popconfirm>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>  



        </div>
        <div v-else-if="noTitleKey === 'vip'">
          <div class="title"><div class="text"><span>套餐管理</span></div></div>
            <div class="table">
              <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="list"
                :pagination="pagination" :loading="loading" @change="handleChangeVip">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.dataIndex == 'id'">
                    <div>
                      <span>{{ record.id }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'createtime'">
                    <div>
                      <span>{{ record.create_time }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'vipname'">
                    <div>
                      <span>{{ record.vipname }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'account'">
                    <div>
                      <span>{{ userinfo.phone }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'amount'">
                    <div>
                      <span>{{ record.total_amount }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'invoice'">
                    <div>
                       <span class="down-button" v-if="record.invoice" @click="downInvoice(record.invoice)">发票下载</span>
                        <span v-else>未开票</span>
                    </div>
                  </template>
                 
                  
                </template>
              </a-table>
            </div>


        </div>
        <div v-else>
          <div class="title"><div class="text"><span>积分管理</span></div></div>
          <div class="table">
              <a-table size="middle" :columns="integralColumns" :row-key="record => record.id" :data-source="list_integral"
                :pagination="pagination" :loading="loading" @change="handleChangeIntegral">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.dataIndex == 'id'">
                    <div>
                      <span>{{ record.id }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'createtime'">
                    <div>
                      <span>{{ record.createtime }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'reason'">
                    <div>
                      <span>{{ record.reason }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'account'">
                    <div>
                      <span>{{ record.account }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'num'">
                    <div>
                      <span>{{ record.num }}</span>
                    </div>
                  </template>
                  <template v-if="column.dataIndex == 'balance'">
                    <div>
                      <span>{{ record.balance }}</span>
                    </div>
                  </template>
                 
                  
                </template>
              </a-table>
            </div>



        </div>

      </a-card>

      
    </div>

    <a-modal
        v-model:visible="visible2"
        title="微信绑定"
        
        :footer="null"
        wrap-class-name="full-modal"
        @ok="handleOk"
      >
      <div class="text-center">
        <img src="@/assets/qrcode.jpg" alt="" class="logo">
        <p>微信扫码绑定</p>
      </div>
    </a-modal>

      <a-modal v-model:visible="visible" title="添加账号" @ok="handleOk">
        <a-input v-model:value="mobile" placeholder="请输入手机号" />
      </a-modal>
  </div>
</template>
<script>
//import { usePagination } from 'vue-request';
import { defineComponent,ref} from 'vue';
//import {Card} from 'ant-design-vue';
import service from '@/service/service';
//import { HomeOutlined,FullscreenOutlined } from '@ant-design/icons-vue';
// import { message, Modal } from 'ant-design-vue';
//import { HolderOutlined } from '@ant-design/icons-vue';
//import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex';
import { message } from 'ant-design-vue';
// 定义scoket
//const socket = ref(null)


export default defineComponent({
  components: {
    //HolderOutlined,
  },
  

  data() {
    return {
      list: [],
      list_integral:[],
      list_subaccount:[],
      visible:0,
      visible2:0,
      mobile:"",
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      subaccountPagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '购买时间',
          dataIndex: 'createtime',
        },
        {
          title: '套餐名称',
          dataIndex: 'vipname',
          width: 400,
        },
        {
          title: "有效期",
          dataIndex: "expiretime",
        },
        {
          title: "支付账户",
          dataIndex: "account",
        },
        {
          title: "支付金额",
          dataIndex: "amount",
        },

        {
          title: '发票下载',
          dataIndex: 'invoice',
        },
      ],

      integralColumns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '消费时间',
          dataIndex: 'createtime',
        },
        {
          title: '功能板块',
          dataIndex: 'reason',
          width: 400,
        },
        {
          title: "操作账户",
          dataIndex: "account",
        },
        {
          title: "积分数量",
          dataIndex: "num",
        },
        {
          title: "积分余额",
          dataIndex: "balance",
        },


      ],
      subaccountColumns: [
        {
          title: 'ID',
          dataIndex: 'userid'
        },
        {
          title: "登录账号",
          dataIndex: "phone",
        },
        {
          title: "操作",
          dataIndex: "operate",
        },


      ],

    }
  },
  mounted() {
    this.handleVip();
    this.handleIntegral();
    this.handleSubaccount();
    console.log(this.$route.query.tab)
    this.noTitleKey = this.$route.query.tab

    if(!this.noTitleKey){
      this.noTitleKey = "info"
    }
    //this.onTabChange(this.noTitleKey)

  },
  computed: {
    ...mapState(['userinfo']),
  },
  methods: {
    downInvoice(e){
      window.open(e)
      //tehis.$route.push(e)
    },

    addAccount(){
      this.visible = 1
    },

    bindwx(){
      this.visible2 = 1
    },

    buyVip(){
      this.$router.push('/work/home/buy-vip')
    },

    async delSubaccount(e){


      let params = {
        userid:e,
      };
      const res = await service.delete_subaccount(params);
      if (res.code) {
        message.info(res.msg);
        this.handleSubaccount();
      }
    },

    async handleOk(){
      let params = {
        phone:this.mobile,
        pid:this.userinfo.userid
      };
      const res = await service.add_subaccount(params);
      if (res.code) {
        message.info(res.msg);
        this.handleSubaccount();
        this.visible = 0
      }
    },

    handleChangeVip(e) {
      this.pagination = { ...e };
      this.handleVip();
    },

    async handleVip() {
      this.loading = true;
      let params = {
        userid:this.userinfo.userid,
        page: this.pagination.current,
        pageSize:this.pagination.pageSize
      };

      const res = await service.get_self_order(params);
      if (res.code) {
        this.pagination = { ...this.pagination, total: res.total_number }
        // console.log(data);
        this.list = res.data;
      }

      this.loading = false;
    },


    handleChangeIntegral(e) {
      this.pagination = { ...e };
      this.handleIntegral();
    },

    async handleIntegral() {
      this.loading = true;
      let params = {
        userid:this.userinfo.userid,
        page: this.pagination.current,
        pageSize:this.pagination.pageSize
      };

      const res = await service.get_integral_list(params);
      if (res.code) {
        this.pagination = { ...this.pagination, total: res.total_number }
        // console.log(data);
        this.list_integral = res.data;
      }

      this.loading = false;
    },

    handleChangeSubaccount(e) {
      this.subaccountPagination = { ...e };
      this.handleSubaccount();
    },

    async handleSubaccount() {
      this.loading = true;
      let params = {
        userid:this.userinfo.userid,
        page: this.subaccountPagination.current,
        pageSize:this.subaccountPagination.pageSize
      };

      const res = await service.get_subaccount_list(params);
      if (res.code) {
        this.subaccountPagination = { ...this.subaccountPagination, total: res.total_number }
        // console.log(data);
        this.list_subaccount = res.data;
      }

      this.loading = false;
    }



  },

  setup() {
    const tabListNoTitle = [
      {
        key: 'info',
        tab: '会员信息',
      },
      {
        key: 'subaccount',
        tab: '子账户管理',
      },
      {
        key: 'vip',
        tab: '套餐管理',
      },{
        key: 'integral',
        tab: '积分管理',
      },
    ];
    const noTitleKey = ref('info');

    const onTabChange = (value) => {
      
        noTitleKey.value = value;
      
     };

    return {
      tabListNoTitle,
      noTitleKey,
      onTabChange,
    };
  },

})


</script>
<style lang="less" scoped>
:deep .ant-card-head{
  background: #003466;
  color: #fff;
  font-size:24px

}
:deep .ant-card-extra{
    font-size: 18px!important;
    color: #ffff00;
  }
:deep .ant-divider-inner-text{
  font-size: 22px;
  color: #666;
  line-height: 1.5;
  padding-bottom: 10px;
}

.text-center{
  text-align: center;
}

:deep .ant-card-head{
  background: #fff;
}

.title{
  display: flex;
  margin-bottom: 30px;
  .text{
    font-size: 28px;
    font-weight: 600;
    color: #555;
    padding-left: 12px;
    margin-right: 12px;
    border-left: 6px solid #3399FF;
    display: flex;
    align-items: flex-end;
  }

}
.down-button{cursor: pointer;}
.addButton{
  padding-top: 20px;
}

</style>